@import '~ngx-toastr/toastr';


@font-face {
  font-family: DunbarText-Regular;
  src: url(/assets/images/DunbarText-Regular.ttf);
}

html {
  background: #030303;
}


::-webkit-scrollbar {
  width: 4px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  background-color: #888;
  border-radius: 20px;
  border: 3px solid #888;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: DunbarText-Regular !important;
  background: #030303 !important;
}


.modal-content {
  border-radius: 10px;
  border: none !important;
  opacity: 1 !important;
  box-shadow: 0 0 6px #0000001a !important;
  background-color: #4C4646 !important;

}

.mat-expansion-panel-header {
  font-family: DunbarText-Regular !important;
  font-size: 15px;
  font-weight: 400;

}


#sidebar .mat-expansion-panel-header {
  padding: 0 5px;
}

#sidebar .mat-expansion-panel {
  margin-top: 10px;
}

.sidebar .logo {
  border-bottom: 0px !important;
}

.dwsv a {
  color: #ffffff;
}

.dcvws .dropdown-toggle::after {
  display: none;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: inherit !important;
  border-color: inherit !important;
}

.dehrf {
  margin-top: 77px;
}

.gkij li {
  width: 100%;
}

.dt-tb .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgb(255 255 255 / 54%);
}

.dt-tb input.mat-input-element {
  margin-top: -0.0625em;
  color: white;
}

.fixed-top.tp-hd {
  background: #111111;
  position: fixed !important;
}

.material-icons.grn {
  color: green;
}

.material-icons.rd {
  color: red;
}


/* Role Management css */

.odny .mat-paginator,
.odny .mat-select-arrow {
  background: #292929;
  color: #fff !important;
}

.odny .mat-select-value-text {
  color: white;
}

.odny .mat-icon-button.mat-button-disabled.mat-button-disabled {
  color: rgb(255 255 255 / 26%);
}

.rol .mat-card-content {
  margin-bottom: 0px;
}


/* common css */
.odny {
  border: 0;
  background: #111111;
  box-shadow: 9px 9px 16px rgb(0 0 0 / 70%), -7px -2px 16px rgb(73 73 73 / 30%) !important;
  border-radius: 20px;
  padding: 4px 10px;
}





@media (min-width: 992px) {
  .modal-lg {
    margin: 1.75rem auto;
    max-width: 700px !important;
  }
}



bs-modal-backdrop.modal-backdrop.fade.in.show {
  z-index: 100;
}

modal-container.modal.fade.show {
  z-index: 100;
}

.mat-form-field {
  width: 100%;
}


.modal-content {
  margin-top: 178px !important
}

.modal-header {
  padding: 15px;
  border-bottom: none !important;
}

.modal-content{
  background: #181818 !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  border: none !important;
  color: #fff;
}

.bs-timepicker-field {
  background-color: black !important;
  color: white !important;
}
.pac-container, .pac-item {
  background-color:#111111 !important;
  cursor: default;
  z-index: 99999;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 0px solid #e6e6e6;
  border-bottom: 0px solid #e6e6e6;
  font-size: 11px;
  color: white;
}

.pac-item-query{
  color: white !important;
}
